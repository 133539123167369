import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/functions';
import * as $ from 'jquery';
import {ParceiroLista} from '../../service/Parceiro';
import {AnalyticsService} from '../../service/analytics.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Colecao} from '../../service/Colecao';
import {Diretorio} from '../../service/Diretorio';
// import Swiper core and required modules
import SwiperCore, {Lazy, Navigation, Pagination, SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import {Router} from "@angular/router";

import { PageService } from '../../service/page.service';

SwiperCore.use([Pagination, Lazy, Navigation]);

function jsFunction(): void {
  $(document).ready(() => {
    // FAV
    $('.fav').on('click', () => {
      $(this).toggleClass('active');
    });
  });
}

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 0, display: 'none'})),
      state('out', style({opacity: 1, display: ''})),
      transition(':enter', animate('800ms ease-out')),
      transition(':leave', animate('100ms ease-in')),
    ]),
  ]
})
export class PageHomeComponent implements OnInit, AfterViewInit {

  @ViewChild('swiperComponent', {static: false}) swiperComponent: SwiperComponent;
  diretorios: Diretorio[];
  colecoes: Colecao[];
  parceiroLista: ParceiroLista;
  loading = true;
  config: SwiperOptions = {
    breakpoints: {
      1280: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      960: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 5,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 5,
      },
      1: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 5,
      }
    },
    autoHeight: true,
    lazy: true,
    navigation: false,
    // navigation: {
    //   prevEl: '.navigation-previous',
    //   nextEl: '.navigation-next'
    // },
    slidesPerView: 3,
    spaceBetween: 10,
    pagination: {
      clickable: true,
    }
  };

  constructor(public analytics: AnalyticsService,
              public router: Router,
              private pageService: PageService) {
  }

  ngOnInit(): void {
    jsFunction();
    this.pageService.setPageData();
  }

  ngAfterViewInit(): void {
    this.fetchColecao();
    this.fetchParceiro();
  }

  fetchColecao(): void {
    this.loading = true;
    firebase.functions().httpsCallable('diretorio')({cod_colecao: 0}).then(value => {
      this.diretorios = value.data;
      this.loading = false;
    });
    firebase.functions().httpsCallable('colecao')({destaque: true}).then(value => {
      this.colecoes = value.data;
    });
  }

  fetchParceiro(): void {
    firebase.functions().httpsCallable('lista_parceiro')({
      nome_lista: 'parceiros'
    }).then(value => {
      this.parceiroLista = value.data[0];
    });
  }

  previousClick(): void {
    this.swiperComponent.swiperRef.slidePrev();
  }

  nextClick(): void {
    this.swiperComponent.swiperRef.slideNext();
  }

}
