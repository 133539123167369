<div class="btn-aside-menu">
  <a title="Menu" class="btn-menu">
    <img src="images/admin-edit/icon-header-menu.svg" alt="Menu" />
  </a>
</div>
<!-- END BUTTON MENU ASIDE -->

<!-- ASIDE MENU COMPONENT -->
<aside class="aside-menu">
  <div class="logo">
    <a href="admin.html" title="SchoolAdvisor">
      <img src="images/admin-edit/logo-col.svg" alt="SchoolAdvisor" />
    </a>
  </div>

  <div class="row mb-3 d-flex align-items-center">
    <div class="title col-9">
      <h2 class="font-size-featured text-black mb-0">Menu Escola</h2>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <a title="Fechar" class="close-menu">
        <img src="images/admin-edit/icon-close-menu.svg" alt="Fechar" />
      </a>
    </div>
  </div>

  <nav>
    <ul>
      <li class="active">
        <a href="admin.html" title="Geral" class="font-size-small text-lightgray d-flex">Geral</a>
      </li>
      <li>
        <a href="#" title="O que as pessoas estão falando" class="font-size-small text-lightgray d-flex">O que as pessoas estão falando</a>
      </li>
      <li>
        <a href="caracteristicas-escola.html" title="Características da escola" class="font-size-small text-lightgray d-flex">Características da escola</a>
      </li>
      <li>
        <a href="avaliacoes.html" title="Avaliações" class="font-size-small text-lightgray d-flex">Avaliações</a>
      </li>
      <li>
        <a href="#" title="Visão do especialista" class="font-size-small text-lightgray d-flex">Visão do especialista</a>
      </li>
      <li>
        <a href="#" title="Perfil da escola" class="font-size-small text-lightgray d-flex">Perfil da escola</a>
      </li>
      <li>
        <a href="#" title="Qualificações da escola" class="font-size-small text-lightgray d-flex">Qualificações da escola</a>
      </li>
      <li>
        <a href="#" title="Sobre a escola" class="font-size-small text-lightgray d-flex">Sobre a escola</a>
      </li>
      <li>
        <a href="niveis-ensino.html" title="Níveis de ensino oferecidos" class="font-size-small text-lightgray d-flex">Níveis de ensino oferecidos</a>
      </li>
      <li>
        <a href="#" title="Eventos e processo de ingresso" class="font-size-small text-lightgray d-flex">Eventos e processo de ingresso</a>
      </li>
      <li>
        <a href="#" title="Assista aos vídeos da escola" class="font-size-small text-lightgray d-flex">Assista aos vídeos da escola</a>
      </li>
      <li>
        <a href="#" title="Infraestrutura e Acessibilidade" class="font-size-small text-lightgray d-flex">Infraestrutura e Acessibilidade</a>
      </li>
      <li>
        <a href="#" title="Professores e time pedagógico" class="font-size-small text-lightgray d-flex">Professores e time pedagógico</a>
      </li>
      <li>
        <a href="#" title="Idiomas" class="font-size-small text-lightgray d-flex">Idiomas</a>
      </li>
      <li>
        <a href="#" title="Esportes e Cultura" class="font-size-small text-lightgray d-flex">Esportes e Cultura</a>
      </li>
    </ul>
  </nav>
</aside>
