import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-aside-menu-edit',
  templateUrl: './aside-menu-edit.component.html',
  styleUrls: ['./aside-menu-edit.component.css'],
})
export class AsideMenuEditComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    let that = this;

    $(document).ready(() => {
      // ASIDE MENU
      $('.btn-aside-menu .btn-menu').on('click', function () {
        that.openMenu();
      });

      // CLOSE ASIDE MENU
      $('.close-menu').on('click', function () {
        that.closeMenu();
      });
    });
  }

  openMenu() {
    $('.aside-menu').addClass('active');
  }

  closeMenu() {
    $('.aside-menu').removeClass('active');
  }
}
