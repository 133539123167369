import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { LOCALE_ID, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { RoutingModule } from './routing/routing.module';
import { HeaderComponent } from './navigation/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidenavComponent } from './navigation/sidenav/sidenav.component';
import { PageAboutComponent } from './page/page-about/page-about.component';
import { PageHomeComponent } from './page/page-home/page-home.component';
import { PageContatoComponent } from './page/page-contato/page-contato.component';
import { PageEscolaComponent } from './page/page-escola/page-escola.component';
import { PageDuvidasComponent } from './page/page-duvidas/page-duvidas.component';
import { PagePoliticaComponent } from './page/page-politica/page-politica.component';
import { PageRegrasComponent } from './page/page-regras/page-regras.component';
import { PageTermosComponent } from './page/page-termos/page-termos.component';
import { MapaComponent, MapaCriarDiretorioPopup, MapaLoading } from './mapa/mapa.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EscolaCardComponent } from './layout/escola-card/escola-card.component';
import { MatCardModule } from '@angular/material/card';
import { EscolaListHorizontalComponent } from './layout/escola-list-horizontal/escola-list-horizontal.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { EscolaDetailComponent, DialogMotivo, DialogRequestInfo, DialogRequestInfoResponse, BottomSheetInfo } from './layout/escola-detail/escola-detail.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EscolaResultComponent } from './layout/escola-result/escola-result.component';
import { EscolaFilterTagsComponent } from './layout/escola-filter-tags/escola-filter-tags.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RatingModule } from 'ngx-bootstrap/rating';
import { EscolaFilterRateComponent } from './layout/escola-filter-rate/escola-filter-rate.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReviewDetailComponent } from './review/review-detail/review-detail.component';
import { EscolaMapComponent } from './layout/escola-map/escola-map.component';
import { ReviewEditComponent, ReviewEditAskComponent } from './review/review-edit/review-edit.component';
import { ReviewListComponent } from './review/review-list/review-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { EscolaFilterListasComponent } from './layout/escola-filter-listas/escola-filter-listas.component';
import { PagePerfilComponent } from './page/page-perfil/page-perfil.component';
import { PageEscolasComponent } from './page/page-escolas/page-escolas.component';
import { PageReviewsComponent } from './page/page-reviews/page-reviews.component';
import { AngularFireModule } from '@angular/fire';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';
import { DropzoneDirective } from './dropzone.directive';
import { UploaderComponent } from './navigation/uploader/uploader.component';
import { UploadTaskComponent } from './navigation/upload-task/upload-task.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { EscolaGalleryComponent } from './layout/escola-gallery/escola-gallery.component';
import { EscolaCompetitorsComponent } from './layout/escola-competitors/escola-competitors.component';
import { ComprasComponent } from './user/compras/compras.component';
import { UserEscolasComponent } from './user/escolas/user-escolas.component';
import { ReviewsComponent } from './user/reviews/reviews.component';
import { EscolaListVerticalComponent } from './layout/escola-list-vertical/escola-list-vertical.component';
import { PageFuncionaComponent } from './page/page-funciona/page-funciona.component';
import { PagePlanosComponent } from './page/page-planos/page-planos.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ButtonToTopComponent } from './navigation/button-to-top/button-to-top.component';
import { EscolaFilterAllComponent } from './layout/escola-filter-all/escola-filter-all.component';
import { MatSliderModule } from '@angular/material/slider';
import { CommonModule, registerLocaleData, DatePipe } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { SafePipe } from './service/safe.pipe';
import { RouterModule } from '@angular/router';
import { TextBoundComponent } from './navigation/text-bound/text-bound.component';
import { ReviewRankingComponent } from './review/review-ranking/review-ranking.component';
import { ButtonFavoriteComponent } from './navigation/button-favorite/button-favorite.component';
import { EscolaProductsComponent } from './layout/escola-products/escola-products.component';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { MatGridListModule } from '@angular/material/grid-list';
import { ListaProprietarioComponent } from './admin/lista-proprietario/lista-proprietario.component';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { PageCellepComponent } from './page/page-cellep/page-cellep.component';
import { PageBlogComponent } from './page/page-blog/page-blog.component';
import { BannerParceiroComponent } from './layout/banner-parceiro/banner-parceiro.component';
import { ListaParceiroComponent } from './admin/lista-parceiro/lista-parceiro.component';
import { UrlNormalizerPipe } from './pipe/url-normalizer.pipe';
import { BottomBannerComponent } from './navigation/bottom-banner/bottom-banner.component';
import { UrlFixerPipe } from './pipe/url-fixer.pipe';
import { PageParceirosComponent } from './page/page-parceiros/page-parceiros.component';
import { PageListaComponent } from './page/page-lista/page-lista.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CalculadoraComponent } from './calculadora/calculadora.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TriStateCheckboxComponent } from './navigation/tri-state-checkbox/tri-state-checkbox.component';
import { ColecaoComponent } from './layout/colecao/colecao.component';
import { DiretorioComponent } from './layout/diretorio/diretorio.component';
import { ButtonRatingComponent } from './navigation/button-rating/button-rating.component';
import { SwiperModule } from 'swiper/angular';
import { ButtonParceiroComponent } from './navigation/button-parceiro/button-parceiro.component';
import { PageDiretorioComponent } from './page/page-diretorio/page-diretorio.component';
import { PageColecaoComponent } from './page/page-colecao/page-colecao.component';
import { TreeColecaoComponent } from './admin/tree-colecao/tree-colecao.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BlurDisplayComponent } from './navigation/blur-display/blur-display.component';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { SignUpComponent } from './navigation/auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './navigation/auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './navigation/auth/verify-email/verify-email.component';
import { LoginDialogComponent, DialogOptIn, DialogYesNo } from './navigation/auth/login-dialog/login-dialog.component';
import { DialogConfirmData } from './navigation/auth/login-dialog/dialog-confirm-data';
import { SignInComponent } from './navigation/auth/sign-in/sign-in.component';
import { AvatarComponent } from './navigation/auth/avatar/avatar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EscolaVideoComponent } from './layout/escola-video/escola-video.component';
import { OneTapComponent } from './navigation/auth/one-tap/one-tap.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { EscolaFilterHeaderComponent } from './layout/escola-filter-header/escola-filter-header.component';
import { PaginatorComponent } from './navigation/paginator/paginator.component';
import { ChatComponent } from './chat/chat.component';
import { AddroomComponent } from './chat/addroom/addroom.component';
import { AdminComponent } from './chat/admin/admin.component';
import { ChatroomComponent } from './chat/chatroom/chatroom.component';
import { RoomlistComponent } from './chat/roomlist/roomlist.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ParceiroComponent } from './layout/parceiro/parceiro.component';
import { PerfilAfinidadeComponent } from './layout/perfil-afinidade/perfil-afinidade.component';
import { EscolaScoreComponent } from './layout/escola-score/escola-score.component';
import { SchoolDetailMoreInfoComponent } from './layout/escola-detail/more-info/school-detail-more-info';
import { SchoolQualificationsComponent } from './layout/escola-detail/qualifications/school-qualifications';
import { Ng5SliderModule } from 'ng5-slider';
import { TemplateOldComponent } from './templates/template-old/template-old.component';
import { TemplateNewComponent } from './templates/template-new/template-new.component';
import { TemplateEditComponent } from './templates/template-edit/template-edit.component';
import { HeaderNewComponent } from './component/header-new/header-new.component';
import { AsideMenuNewComponent } from './component/aside-menu-new/aside-menu-new.component';
import { FooterNewComponent } from './component/footer-new/footer-new.component';
import { ButtonGoTopNewComponent } from './component/button-go-top-new/button-go-top-new.component';
import { EscolaDetailNewComponent } from './layout/escola-detail-new/escola-detail-new.component';
import { BuscarEscolaComponent } from './component/buscar-escola/buscar-escola.component';
import { EscolaGalleryNewComponent } from './component/escola-gallery-new/escola-gallery-new.component';
import { EscolaInfoComponent } from './component/escola-info/escola-info.component';
import { EscolaSobreComponent } from './component/escola-sobre/escola-sobre.component';
import { EscolaCaracteristicasComponent } from './component/escola-caracteristicas/escola-caracteristicas.component';
import { EscolaDepoimentosComponent } from './component/escola-depoimentos/escola-depoimentos.component';
import { EscolaVisaoEspecialistaComponent } from './component/escola-visao-especialista/escola-visao-especialista.component';
import { EscolaPerfilComponent } from './component/escola-perfil/escola-perfil.component';
import { EscolaQualificacoesComponent } from './component/escola-qualificacoes/escola-qualificacoes.component';
import { EscolaMaisSobreComponent } from './component/escola-mais-sobre/escola-mais-sobre.component';
import { EscolaNiveisOferecidosComponent } from './component/escola-niveis-oferecidos/escola-niveis-oferecidos.component';
import { EscolaEventosComponent } from './component/escola-eventos/escola-eventos.component';
import { EscolaVideosComponent } from './component/escola-videos/escola-videos.component';
import { EscolaInfraAcessibilidadeComponent } from './component/escola-infra-acessibilidade/escola-infra-acessibilidade.component';
import { EscolaProfessoresTimePedagogicoComponent } from './component/escola-professores-time-pedagogico/escola-professores-time-pedagogico.component';
import { EscolaIdiomasComponent } from './component/escola-idiomas/escola-idiomas.component';
import { EscolaEsportesCulturaComponent } from './component/escola-esportes-cultura/escola-esportes-cultura.component';
import { EscolaAsideComponent } from './component/escola-aside/escola-aside.component';
import { EscolaArtigosComponent } from './component/escola-artigos/escola-artigos.component';
import { EscolaParceriasECertificacoesComponent } from './component/escola-parcerias-e-certificacoes/escola-parcerias-e-certificacoes.component';
import { EscolaPublicidadeComponent } from './component/escola-publicidade/escola-publicidade.component';
import { EscolaSobreRegiaoComponent } from './component/escola-sobre-regiao/escola-sobre-regiao.component';
import { EscolaAdsSlideComponent } from './component/escola-ads-slide/escola-ads-slide.component';
import { EscolaRecomendadasComponent } from './component/escola-recomendadas/escola-recomendadas.component';
import { EscolaModalAvaliarComponent } from './component/escola-modal-avaliar/escola-modal-avaliar.component';
import { EscolaDetailEditComponent } from './component/escola-detail-edit/escola-detail-edit.component';
import { EscolaEventosEditComponent } from './component/escola-eventos-edit/escola-eventos-edit.component';
import { EscolaModalShareComponent } from './component/escola-modal-share/escola-modal-share.component';
import { SeloEscolaComponent } from './component/selo-escola/selo-escola.component';
import { AsideMenuEditComponent } from './component/aside-menu-edit/aside-menu-edit.component';
import { FooterEditComponent } from './component/footer-edit/footer-edit.component';
import { GeralComponent } from './layout/edit/geral/geral.component';
import { RedirectGuard } from './service/redirectGuard';

registerLocaleData(localeBr, 'pt-BR');

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'schooladvisor.com.br',
  },
  palette: {
    popup: {
      background: '#edeff5',
      text: '#838391',
    },
    button: {
      background: '#ff4081',
      text: '#ffffff',
    },
  },
  theme: 'classic',
  position: 'bottom-right',
  content: {
    message: 'Usamos cookies e outras tecnologias para melhorar sua experiência com nossos serviços e personalizar publicidade de acordo com seus interesses.' + '<br>Ao continuar navegando, você concorda com a nossa: ',
    dismiss: 'Ok',
    link: 'Política de Privacidade',
    href: 'https://www.blog.schooladvisor.com.br/privacidade',
  },
};

@NgModule({
  declarations: [AppComponent, SafePipe, HeaderComponent, LayoutComponent, SidenavComponent, 
    PageAboutComponent, PageHomeComponent, PageContatoComponent, PageEscolaComponent, 
    PageDuvidasComponent, PagePoliticaComponent, PageRegrasComponent, PageTermosComponent, 
    MapaComponent, MapaLoading, MapaCriarDiretorioPopup, EscolaCardComponent, 
    EscolaListHorizontalComponent, FooterComponent, EscolaDetailComponent, EscolaResultComponent, 
    EscolaFilterTagsComponent, DialogOptIn, DialogConfirmData, DialogYesNo, EscolaFilterRateComponent, 
    ReviewDetailComponent, EscolaMapComponent, ReviewEditComponent, ReviewListComponent, 
    EscolaFilterListasComponent, PagePerfilComponent, PageEscolasComponent, PageReviewsComponent, 
    DropzoneDirective, UploaderComponent, UploadTaskComponent, EscolaGalleryComponent, 
    EscolaCompetitorsComponent, ComprasComponent, UserEscolasComponent, ReviewsComponent, 
    EscolaListVerticalComponent, PageFuncionaComponent, PagePlanosComponent, ButtonToTopComponent, 
    EscolaFilterAllComponent, TextBoundComponent, ReviewRankingComponent, ButtonFavoriteComponent, 
    ReviewEditAskComponent, EscolaProductsComponent, ListaProprietarioComponent, PageCellepComponent, 
    PageBlogComponent, BannerParceiroComponent, ListaParceiroComponent, UrlNormalizerPipe, 
    BottomBannerComponent, UrlFixerPipe, PageParceirosComponent, PageListaComponent, 
    CalculadoraComponent, DialogMotivo, BottomSheetInfo, DialogRequestInfo, DialogRequestInfoResponse, 
    TriStateCheckboxComponent, ColecaoComponent, DiretorioComponent, ButtonRatingComponent, 
    ButtonParceiroComponent, PageDiretorioComponent, PageColecaoComponent, TreeColecaoComponent, 
    BlurDisplayComponent, SignUpComponent, ForgotPasswordComponent, VerifyEmailComponent, 
    LoginDialogComponent, SignInComponent, AvatarComponent, EscolaVideoComponent, OneTapComponent, 
    EscolaFilterHeaderComponent, PaginatorComponent, ChatComponent, AddroomComponent, AdminComponent, 
    ChatroomComponent, RoomlistComponent, ParceiroComponent, PerfilAfinidadeComponent, 
    EscolaScoreComponent, SchoolDetailMoreInfoComponent, SchoolQualificationsComponent, TemplateOldComponent, TemplateNewComponent, TemplateEditComponent, HeaderNewComponent, AsideMenuNewComponent, FooterNewComponent, ButtonGoTopNewComponent, EscolaDetailNewComponent, BuscarEscolaComponent, EscolaGalleryNewComponent, EscolaInfoComponent, EscolaSobreComponent, EscolaCaracteristicasComponent, EscolaDepoimentosComponent, EscolaVisaoEspecialistaComponent, EscolaPerfilComponent, EscolaQualificacoesComponent, EscolaMaisSobreComponent, EscolaNiveisOferecidosComponent, EscolaEventosComponent, EscolaVideosComponent, EscolaInfraAcessibilidadeComponent, EscolaProfessoresTimePedagogicoComponent, EscolaIdiomasComponent, EscolaEsportesCulturaComponent, EscolaAsideComponent, EscolaArtigosComponent, EscolaParceriasECertificacoesComponent, EscolaPublicidadeComponent, EscolaSobreRegiaoComponent, EscolaAdsSlideComponent, EscolaRecomendadasComponent, EscolaModalAvaliarComponent, EscolaDetailEditComponent, EscolaEventosEditComponent, EscolaModalShareComponent, SeloEscolaComponent, AsideMenuEditComponent, FooterEditComponent, GeralComponent],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    RoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    MatTabsModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatSliderModule,
    MatGridListModule,
    MatTableModule,
    MatTreeModule,
    MatRadioModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxSliderModule,
    SlickCarouselModule,
    SwiperModule,
    RatingModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    AngularFireModule.initializeApp({
      apiKey: environment.firebaseConfig.apiKey,
      authDomain: environment.firebaseConfig.authDomain,
      databaseURL: environment.firebaseConfig.databaseURL,
      projectId: environment.firebaseConfig.projectId,
      storageBucket: environment.firebaseConfig.storageBucket,
      messagingSenderId: environment.firebaseConfig.messagingSenderId,
      appId: environment.firebaseConfig.appId,
      measurementId: environment.firebaseConfig.measurementId,
    }),
    AngularFireAnalyticsModule,
    NgbModule,
    GalleryModule,
    LightboxModule.withConfig({}),
    NgxMaskModule.forRoot(),
    NgxMaterialTimepickerModule.setLocale('pt-BR'),
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxChartsModule,
    ClipboardModule,
    MatButtonToggleModule,
    MatOptionModule,
    CommonModule,
    CommonModule,
    GoogleMapsModule,
    OverlayModule,
    DragDropModule,
    Ng5SliderModule,
    ShareButtonsModule,
    ShareIconsModule,
  ],
  exports: [],
  providers: [
    UrlNormalizerPipe,
    UrlFixerPipe,
    DatePipe,
    RedirectGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    // {provide: MatPaginatorIntl, useValue: getEscolaPaginatorIntl()},
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
    { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9000] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    // {
    //   provide: CONFIG, useValue: {
    //     // send_page_view: true,
    //     // allow_ad_personalization_signals: false,
    //     // anonymize_ip: true
    //   }
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.loadIcons();
  }

  loadIcons(): void {
    this.iconRegistry.addSvgIcon('apple', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/apple.svg')).addSvgIcon('azure', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/azure.svg')).addSvgIcon('bitbucket', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/bitbucket.svg')).addSvgIcon('discord', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/discord.svg')).addSvgIcon('facebook', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/facebook.svg')).addSvgIcon('github', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/github.svg')).addSvgIcon('google', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/google.svg')).addSvgIcon('microsoft', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/microsoft.svg')).addSvgIcon('phone', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/phone.svg')).addSvgIcon('twitch', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/twitch.svg')).addSvgIcon('twitter', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/social-network/twitter.svg'));
  }
}
