import { AfterContentChecked, AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { EscolaService } from '../../service/escola.service';
import { Endereco, Escola, EscolaBeneficio, Social, Unidades, EscolaLista, tags, educacaoBilingue } from '../../service/Escola';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { PagseguroService } from '../../service/pagseguro.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Observable, timer } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { document } from 'ngx-bootstrap/utils';
import { ReviewRankingComponent } from '../../review/review-ranking/review-ranking.component';
import { UserService } from '../../service/user.service';
import { EscolaProductsComponent } from '../../layout/escola-products/escola-products.component';
import { diff as jdiff } from 'jsondiffpatch';
import { UploadFileData } from '../../navigation/upload-task/upload-task.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, Validators } from '@angular/forms';
import { EscolaGalleryComponent } from '../../layout/escola-gallery/escola-gallery.component';
import { SchoolDetailMoreInfoComponent } from '../../layout/escola-detail/more-info/school-detail-more-info';
import { SchoolQualificationsComponent } from '../../layout/escola-detail/qualifications/school-qualifications';
import * as clone from 'clone';
import { DefaultSliderConfig } from '../../navigation/Slider';
import { Meta, Title } from '@angular/platform-browser';
import { AnalyticsService } from '../../service/analytics.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as _moment from 'moment';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AbordagemPedagogica, AbordagemPedagogicaText } from '../../service/Tag';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginDialogComponent } from '../../navigation/auth/login-dialog/login-dialog.component';
import { UrlFixerPipe } from '../../pipe/url-fixer.pipe';
import { BottomSheetInfo, DialogMotivo, DialogRequestInfoResponse } from 'src/app/layout/escola-detail/escola-detail.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export const Bookmarks = [
  'dados',
  'indices',
  'sobre',
  'videos',
  'nivel_ensino',
  'infraestrutura',
  'video360',
  'acessibilidade',
  'recursos',
  'tecnologia',
  'docentes',
  'idiomas',
  'cursos',
  'disciplinas',
  'material',
  'alimentacao',
  // 'parcerias',
  // 'certificados',
  'avaliacao',
];

@Component({
  selector: 'app-escola-detail-edit',
  templateUrl: './escola-detail-edit.component.html',
  styleUrls: ['../../../custom-theme.scss', '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css', '../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css', '../../../styles.scss', '../../../../node_modules/swiper/swiper-bundle.css', '../../../../node_modules/font-awesome/css/font-awesome.css', '../../../../node_modules/@loadingio/loading.css/dist/loading.css', '../../../../node_modules/@loadingio/loading.css/dist/loading.utils.css', '../../../../node_modules/slick-carousel/slick/slick.scss', '../../../../node_modules/slick-carousel/slick/slick-theme.scss', '../../../css/bootstrap-grid.css', '../../../css/bootstrap-reboot.css', '../../../css/mdb.min.css', '../../../../node_modules/cookieconsent/build/cookieconsent.min.css', '../../../css/custom.css', './escola-detail-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EscolaDetailEditComponent implements OnInit, AfterContentChecked, AfterViewInit {
  @Output() callbackEvent = new EventEmitter<void>();
  escola: Escola;
  moment = _moment;
  escolaService = EscolaService;
  escolaOld: Escola;
  socialOld: Social;
  codEscolaGallery: number;
  escolaBeneficio: EscolaBeneficio;
  escolaBeneficioOld: EscolaBeneficio;
  familyChoice = false;
  endereco: Endereco;
  fragment: any;
  textAbout = false;
  escolaListaArray: EscolaLista;
  outrasUnidades: Unidades[];
  mapSizeWidth = 300;
  mapSizeHeight = 160;
  sliderUnidades = DefaultSliderConfig;
  iconFile: File;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagControl = new FormControl();
  urlValidatePattern = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  urlValidatePatternYoutube = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  // videosControl = new FormControl(null, Validators.pattern('(https://www.youtube.com/embed/)|(https://player.vimeo.com/).*'));
  videosControl = new FormControl(null, Validators.pattern(this.urlValidatePatternYoutube));
  videos360Control = new FormControl(null, Validators.pattern(this.urlValidatePattern));

  errorMessageGenero: boolean = false;
  errorMessageDiversidadesEstudantes: boolean = false;
  errorMessageNacionalidadeEstudantes: boolean = false;
  errorMessageUniversidadesEstudantes: boolean = false;

  errorMessageGeneroProfessores: boolean = false;
  errorMessageDiversidadesProfessores: boolean = false;
  errorMessageNacionalidadeProfessores: boolean = false;

  @ViewChild('buyButton', { static: true }) buyButton: HTMLButtonElement;
  saving = false;

  @ViewChild('escolaGallery', { static: false }) escolaGalleryComponent: EscolaGalleryComponent;
  @ViewChild('schoolDetailMoreInfo', { static: false }) schoolDetailMoreInfo: SchoolDetailMoreInfoComponent;
  @ViewChild('schoolQualifications', { static: false }) schoolQualifications: SchoolQualificationsComponent;

  filteredTags: Observable<string[]>;
  @Input() editing = false;
  recursosPedagogicosGroup: string[] = [];
  recursosRecreativosGroup: string[] = [];
  tecnologiaInternetGroup: string[] = [];
  tecnologiaRecursoGroup: string[] = [];
  messagePremium = 'Solicitar Informação';
  abordagemPedagogica = AbordagemPedagogicaText;
  alimentacao = ['Incluso na mensalidade', 'Incluso na mensalidade somente para integral', 'Pago à parte', 'Não oferece'];

  turnos = ['Regular', 'Semi-Integral', 'Integral', 'Estendido', 'Curso de férias'];
  processoSeletivo = ['Vagas imediatas', 'Lista de espera', 'Processo seletivo', 'Sondagem pedagógica'];
  meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  idiomas = ['Português', 'Alemão', 'Espanhol', 'Francês', 'Inglês', 'Italiano', 'Japonês', 'Madarim'];
  competicoes = ['Atletismo', 'Arquearia', 'Artes Marciais', 'Badminton', 'Ballet', 'Basquete', 'Circo', 'Danças', 'Esgrima', 'Futebol', 'Ginástica', 'Handebol', 'Hip Hop', 'Hoquei', 'Ioga', 'Judô', 'Natação', 'Patinação', 'Rugby', 'Skate', 'Tênis', 'Tênis de Mesa', 'Voleibol', 'Catequese', 'Culinária', 'Educação Ambiental', 'Educação Financeira', 'Empreendedorismo', 'Horticultura', 'Idiomas', 'Libras', 'Maker', 'Orient. Vocacional', 'Prep. Vestibular', 'Prep. ENEM', 'Robótica', 'Desenho', 'Literatura', 'Música', 'Prod. Artística', 'Teatro', 'SuperCérebro'];
  modalidadeEnsino = ['', 'Não bilíngue', 'Bilíngue', 'Programa bilíngue opcional', 'Internacional', 'Programa internacional opcional'];
  parceira_cellep = ['Intracurricular', 'Extracurricular'];
  showPremiumFields = true;
  maxAttributos = 3;
  private indexItemId: string;
  private signalScrollTo = false;
  single = [];
  graph_indices_data: { name: string; series: { name: string; value: number }[] }[] = [];
  colorScheme = {
    domain: ['#ef3f5d', '#ef3fd8', '#8b3fef', '#3f82ef', '#3fc9ef', '#3fef88', '#80ef3f', '#efb73f', '#ef593f'],
  };
  indicesInfo: { title: string; description: string; items: string[] }[] = [
    {
      title: 'ICDSA',
      description: 'O indicador classifica o corpo docente da escola segundo os seguintes critérios:',
      items: ['Adequação de Formação Docente  (INEP) - Adequação de formação de docentes à disciplina que leciona', 'Indicador de Regularidade Docente (INEP) - Permanência do docente na escola nos últimos cinco anos (2016 a 2020)', 'Docentes com Curso Superior (INEP) - Percentual de docentes com curso superior completo', 'Avaliações quantitativas (SA) - Avaliações realizadas no site SchoolAdvisor'],
    },
    {
      title: 'IIESA',
      description: 'O indicador classifica a infra estrutura da escola segundo os seguintes critérios:',
      items: ['Salas disponíveis (SIMCAQ) - Quantidade de salas disponíveis em relação ao tamanho das turmas', 'Padrão de qualidade referência (SIMCAQ) - Infraestrutura mínima para atendimento das turmas', 'Infraestrutura (SA) - Infraestrutura esperada para atendimento das turmas', 'Avaliações quantitativas (SA) - Avaliações realizadas no site SchoolAdvisor'],
    },
  ];
  chatVisible = false;
  chatBadge: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public userService: UserService, private pagseguroService: PagseguroService, private snackBar: MatSnackBar, private matDialog: MatDialog, private _bottomSheet: MatBottomSheet, private render: Renderer2, private title: Title, private meta: Meta, private analytics: AnalyticsService, private httpClient: HttpClient, private urlFixer: UrlFixerPipe) {
    fromEvent(window, 'scroll')
      .pipe(debounceTime(100))
      .subscribe(() => {
        let lastItem: HTMLElement = null;
        let minTop: number = null;
        for (const item of Bookmarks) {
          const el: HTMLElement = document.getElementById(item);
          if (el) {
            const top = el.getBoundingClientRect().top;
            // console.log(`${item}: ${top}`);
            const itemId = item + 'Item';
            const elItem: HTMLElement = document.getElementById(itemId);
            this.render.removeClass(elItem, 'active');
            if (top > 0) {
              minTop = minTop ?? top;
              if (top <= minTop) {
                minTop = top;
                lastItem = elItem;
                this.indexItemId = itemId;
              }
            }
          } else {
            // console.log(`unused item for bookmark: ${item}`);
          }
        }

        if (lastItem) {
          this.render.addClass(lastItem, 'active');
        }
      });

    /*this.filteredTags = this.tagControl.valueChanges.pipe(
        startWith(null),
        map((item: string | null) => this._filter(item))
    );*/

    Object.assign(this, { single: this.single });
  }

  validateTotal(): void {
    const masculino = this.convertToNumber(this.escola.matriculas_masculino);
    const feminino = this.convertToNumber(this.escola.matriculas_feminino);
    const outros = this.convertToNumber(this.escola.matriculas_outros);

    const total = masculino + feminino + outros;

    this.errorMessageGenero = total > 100;
  }

  validateTotalDiversidadesEstudantes(): void {
    const total = this.escola.matriculas_racas.reduce((acc, item) => acc + this.convertToNumber(item.quantidade?.toString()), 0);

    this.errorMessageDiversidadesEstudantes = total > 100;
  }

  validateTotalNacionalidadeEstudantes(): void {
    const total = this.escola.matriculas_nacionalidades.reduce((acc, item) => acc + this.convertToNumber(item.quantidade?.toString()), 0);

    this.errorMessageNacionalidadeEstudantes = total > 100;
  }

  validateTotalUniversidadesEstudantes(): void {
    return;

    const total = this.escola.matriculas_universidades.reduce((acc, item) => acc + this.convertToNumber(item.quantidade?.toString()), 0);

    this.errorMessageUniversidadesEstudantes = total > 100;
  }

  validateTotalProfessores(): void {
    const masculino = this.convertToNumber(this.escola.professores_feminino);
    const feminino = this.convertToNumber(this.escola.professores_masculino);
    const outros = this.convertToNumber(this.escola.professores_outros);

    const total = masculino + feminino + outros;

    this.errorMessageGeneroProfessores = total > 100;
  }

  validateTotalDiversidadesProfessores(): void {
    const total = this.escola.professores_racas.reduce((acc, item) => acc + this.convertToNumber(item.quantidade?.toString()), 0);

    this.errorMessageDiversidadesProfessores = total > 100;
  }

  validateTotalNacionalidadeProfessores(): void {
    const total = this.escola.professores_nacionalidades.reduce((acc, item) => acc + this.convertToNumber(item.quantidade?.toString()), 0);

    this.errorMessageNacionalidadeProfessores = total > 100;
  }

  convertToNumber(value: string | null | undefined): number {
    if (!value || isNaN(Number(value.replace('%', '').trim()))) {
      return 0;
    }
    return Number(value.replace('%', '').trim());
  }

  ngAfterContentChecked(): void {
    if (this.signalScrollTo && this.fragment != null) {
      this.scrollTo(this.fragment);
    }
  }

  /*private _filter(value: string): string[] {
    const possibleValues = clone(tags);
    if (this.escola?.tags) {
      for (const selectedItem of this.escola.tags) {
        const index = possibleValues.indexOf(selectedItem);
        // console.log(`check for: ${selectedItem} = ${index}`);
        if (index >= 0) {
          possibleValues.splice(index, 1);
        }
      }
    }

    if (value == null) {
      return possibleValues;
    }

    const filterValue = value.toLowerCase();

    return possibleValues.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
  }*/

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((routeParams) => {
      this.escola = null;
      this.editing = false;
      this.mapSizeWidth = innerWidth / 2;
      const codEscola = this.activatedRoute.snapshot.paramMap.get('codigo');
      const nomeEscola = this.activatedRoute.snapshot.paramMap.get('nome');

      this.updateTags(nomeEscola);
      this.loadEscolas(codEscola);

      // firebase.functions().httpsCallable('view_lista_escola_ativa')
      // ({nome_lista: 'Escolas Novas'}) // TODO: change to specific school nome_lista
      //   .then(value => {
      //     this.escolaListaArray = value.data;
      //   });

      firebase
        .functions()
        .httpsCallable('escola_unidades')(
          // TODO: include this array in escolas funciton
          { cod_escola: codEscola }
        )
        .then((value) => {
          this.outrasUnidades = value.data;
        });

      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    this.activatedRoute.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      console.log('chamei');
      this.editingClick();
    }, 3000);
  }

  private updateTags(nomeEscola: string = null): void {
    if (this.escola == null) {
      if (nomeEscola) {
        this.meta.updateTag({ name: 'description', content: `${nomeEscola}` });
      }
    } else {
      this.title.setTitle(`SchoolAdvisor | ${this.escola.nome}`);
      const description = `description: Confira todas as informações de  ${this.escola.nome}. Buscador com Informações Completas de todas as Escolas Particulares.`;
      const keyword = `schooladvisor, ${this.escola.nome}, ${this.escola.endereco}, ${JSON.stringify(this.escola.nivel_ensino)}`;
      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ name: 'keyword', content: keyword });
    }
  }

  private updateGraph(): void {
    const indices = this.escola.indices;
    this.graph_indices_data = [{ name: 'Índices', series: [] }];
    for (const item of Object.keys(indices)) {
      const value = indices[item];
      // console.log(`${item}: ${value}`);
      if (value != null) {
        this.graph_indices_data[0].series.push({ name: item, value });
      }
    }
    // this.graph_indices_data = [
    //   {
    //     name: 'Indicadores',
    //     series: [
    //       {
    //         name: '2010',
    //         value: 7300000
    //       },
    //       {
    //         name: '2011',
    //         value: 8940000
    //       }
    //     ]
    //   },
    // ];
  }

  private loadEscolas(codEscola: any): void {
    const perfil = this.userService.userData?.perfil;
    const data: any = { codigo: codEscola };
    if (perfil) {
      data.perfil = perfil;
    }

    firebase
      .functions()
      .httpsCallable('escola')(data)
      .then((value) => {
        if (value.data.rows.length > 0) {
          this.escola = value.data.rows[0];
          console.log('escola', this.escola);
          if (!this.escola.matriculas_universidades) {
            this.escola.matriculas_universidades = [];
          }
          if (!this.escola.matriculas_racas) {
            this.escola.matriculas_racas = [];
          }
          if (!this.escola.professores_racas) {
            this.escola.professores_racas = [];
          }
          if (!this.escola.competicoes) {
            this.escola.competicoes = [];
          }
          let inep: any = this.escola.inep;
          try {
            inep = parseInt(inep);
          } catch (e) {
            inep = this.escola.codigo;
          }

          if (this.escola.codigo !== inep) {
            const escolaUrl = this.escola.nome.replace(/ /g, '-');
            const domain = window.location.origin;
            let url = `${domain}/escolas/${this.escola.inep}/${escolaUrl}`;
            url = this.urlFixer.transform(url);
            url = url.replace(/\(/g, '').replace(/\)/g, '');
            const meta = document.createElement('meta');
            meta.httpEquiv = 'refresh';
            meta.content = `0;url=${url}`;
            //console.log("url", url)

            document.getElementsByTagName('head')[0].appendChild(meta);
            return;
          }

          this.codEscolaGallery = this.escola.codigo;
          this.updateTags();
          this.updateGraph();
          // this.endereco = this.escolaService.parseEndereco(this.escola);
          this.recursosPedagogicosGroup = this.escolaService.getGrupo('recursos_pedagogico', true, this.escola);
          this.recursosRecreativosGroup = this.escolaService.getGrupo('recursos_recreativo', true, this.escola);
          this.tecnologiaInternetGroup = this.escolaService.getGrupo('tecnologia_internet', true, this.escola);
          this.tecnologiaRecursoGroup = this.escolaService.getGrupo('tecnologia_recurso', true, this.escola);
          (window as any)._escola = this.escola;
          if (this.escola.lista_escolas != null && !this.escola.premium) {
            this.escolaListaArray = {
              nome_lista: 'Outras escolas na região',
              cod_escola: this.escola.codigo,
            };
          }
          this.signalScrollTo = true;
        }

        this.escolaBeneficio = value.data.rows[0].beneficios;

        if (this.escolaBeneficio == null) {
          this.escolaBeneficio = {
            codigo: this.escola.codigo,
            tipo: 0,
          };
        }

        if (this.escolaBeneficio.tipo === null || this.escolaBeneficio.tipo === undefined) this.escolaBeneficio.tipo = 0;

        if (this.escolaBeneficio.codigo === null || this.escolaBeneficio.codigo === undefined) {
          this.escolaBeneficio.codigo = this.escola.codigo;
        }

        console.log('escolaBeneficio', this.escolaBeneficio);
      });

    firebase
      .functions()
      .httpsCallable('escola_familys_choice')({ codigo: codEscola })
      .then((value) => {
        console.log('familyChoice', value.data);
        this.familyChoice = value.data;
      });
  }

  addMatriculasUniversidades(): void {
    this.escola.matriculas_universidades.push({
      title: '',
      quantidade: null,
      ano_referencia: '',
    });
  }

  removeMatriculasUniversidades(index: number): void {
    this.escola.matriculas_universidades.splice(index, 1);
  }

  addDiversidadesEstudantes(): void {
    this.escola.matriculas_racas.push({
      raca: '',
      quantidade: null,
    });
  }

  removeDiversidadesEstudantes(index: number): void {
    this.escola.matriculas_racas.splice(index, 1);
  }

  addDiversidadesProfessores(): void {
    this.escola.professores_racas.push({
      raca: '',
      quantidade: null,
    });
  }

  removeDiversidadesProfessores(index: number): void {
    this.escola.professores_racas.splice(index, 1);
  }

  addNacionalidadeEstudantes(): void {
    this.escola.matriculas_nacionalidades.push({
      nacionalidade: '',
      quantidade: null,
    });
  }

  removeNacionalidadeEstudantes(index: number): void {
    this.escola.matriculas_nacionalidades.splice(index, 1);
  }

  addNacionalidadeProfessores(): void {
    this.escola.professores_nacionalidades.push({
      nacionalidade: '',
      quantidade: null,
    });
  }

  removeNacionalidadeProfessores(index: number): void {
    this.escola.professores_nacionalidades.splice(index, 1);
  }

  addCompeticoes(): void {
    this.escola.competicoes.push('');
  }

  removeCompeticoes(index: number): void {
    this.escola.competicoes.splice(index, 1);
  }

  trackByIndex(index: number, item: any): any {
    return index;
  }

  get educacaoBilingue() {
    return educacaoBilingue;
  }

  scrollTo(elementId: string): void {
    const el: HTMLElement = document.getElementById(elementId);
    // console.log(`ElementId: ${elementId} el: ${el}`);
    if (el) {
      window.scrollBy({ behavior: 'smooth', top: el.getBoundingClientRect().top - 100 });
      this.signalScrollTo = false;
    }
  }

  showRankingCompleto(): void {
    this.matDialog.open(ReviewRankingComponent, {
      id: 'rankingCompletoDialog',
      data: {
        escola: this.escola,
        readonly: true,
      },
    });
  }

  /**
   * Botão 1: Você receberá informações completas sobre essa escola, são até 110 itens de avaliação objetiva!
   * Prazo de entrega: até 10 dias úteis. (COMPRAR)
   *
   * Botão 2: Quero comprar uma curadoria para me ajudar a escolher 5 escolas e o comparativo das 5 escolas.
   * Você receberá os próximos-passos por e-mail. Prazo de entrega: até 15 dias úteis. (COMPRAR)
   *
   * Botão 3: Ainda tenho dúvidas. Quero falar com vocês. (FALE CONOSCO)
   */
  checkout(event: any): void {
    this.matDialog.open(EscolaProductsComponent, { id: 'escola-products', data: this.escola });
  }

  editingClick(): void {
    // this.escolaOld = {...this.escola};
    // this.escolaOld.social = {...this.escola.social};
    // this.escolaOld.tags = {...this.escola.tags};
    // this.escolaOld.videos = {...this.escola.videos};

    this.schoolDetailMoreInfo.detectChanges();
    //this.schoolQualifications.detectChanges();
    this.escolaOld = clone(this.escola);
    this.escolaBeneficioOld = clone(this.escolaBeneficio);
    this.editing = true;
  }

  async saveEdit(): Promise<void> {
    console.log('escolaBeneficio', this.escolaBeneficio);
    const e = document.getElementsByTagName('mat-error');
    // console.log('error? ' + e.length);

    if (e.length > 0) {
      window.scrollBy({ behavior: 'smooth', top: e[0].getBoundingClientRect().top - 150 });
      this.snackBar.open('Existem campos inválidos.', 'Ok', { duration: 5000 });
      return;
    }

    this.editing = false;
    this.saving = true;

    // ReCreate escola.nivel_ensino array based on its subvalues
    this.escolaService.updateNivelEnsino(this.escola);

    const newData: any = {}; // this.diff(this.escolaAux, this.escola);
    const deltaEscola = jdiff(this.escolaOld, this.escola);
    const deltaBeneficio = jdiff(this.escolaBeneficioOld, this.escolaBeneficio);
    // console.log('Old: ' + JSON.stringify(this.escolaOld.social));
    // console.log('New: ' + JSON.stringify(this.escola.social));

    if (deltaEscola) {
      const deltaKeys: string[] = Object.keys(deltaEscola);
      for (const key of deltaKeys) {
        newData[key] = this.escola[key];
      }
      if (Object.keys(newData).length > 0) {
        newData.codigo = this.escolaOld.codigo;

        // console.log(JSON.stringify(newData));

        let value = await firebase.functions().httpsCallable('escola_update')(newData);

        try {
          if (value.data.length > 0) {
            this.escola = value.data[0];
            this.loadEscolas(this.escola.codigo);
          } else {
            this.snackBar.open('Ocorreu um erro ao salvar os dados da Escola.', 'Ok', { duration: 5000 });
            this.escola = clone(this.escolaOld);
          }
        } catch (e) {
          if (!deltaBeneficio) {
            this.snackBar.open('Ocorreu um erro ao salvar os dados da Escola.', 'Ok', { duration: 5000 });
            this.escola = clone(this.escolaOld);
          }
        }

        this.saving = false;
      } else {
        this.saving = false;
      }
    }

    if (deltaBeneficio) {
      console.log('deltaBeneficio', deltaBeneficio);

      let value = await firebase.functions().httpsCallable('escola_benefits_update')(this.escolaBeneficio);

      this.escolaBeneficioOld = clone(this.escolaBeneficio);
    }

    if (!deltaEscola && !deltaBeneficio) {
      this.snackBar.open('Nenhuma informação foi alterada.', 'Ok', { duration: 2000 });
      this.saving = false;
    }

    this.callbackEvent.emit();
  }

  cancelEdit(): void {
    this.escola = this.escolaOld;
    this.escolaBeneficio = this.escolaBeneficioOld;
    this.editing = false;

    this.callbackEvent.emit();
  }

  /*addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    for (const item of this.escola.tags) {
      // console.log(`check for: ${value} = ${item}`);
      if (item === value) {
        return;
      }
    }

    // Add our tag
    if ((value || '').trim()) {
      this.escola.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagControl.setValue(null);
  }*/

  /*removeTag(tag: string): void {
    const index = this.escola.tags.indexOf(tag);

    if (index >= 0) {
      this.escola.tags.splice(index, 1);
    }
  } */

  /*selectedTag(event: MatAutocompleteSelectedEvent): void {
    if (!this.escola.tags) {
      this.escola.tags = [];
    }

    this.escola.tags.push(event.option.viewValue);
    this.tagsInput.nativeElement.value = null;
    this.tagControl.setValue('');

    requestAnimationFrame(() => {
      this.autoTrigger.openPanel();
    });
  }

  selectedOption(event: MatSelectChange): void {
    if (!this.escola.tags) {
      this.escola.tags = [];
    }

    if (this.escola.tags.indexOf(event.value) >= 0) {
      return;
    }

    this.escola.tags.push(event.value);
    this.tagsInput.nativeElement.value = null;
    this.tagControl.setValue('');
  }*/

  chooseIconFile(event: any): void {
    this.iconFile = event.target.files[0];
  }

  uploadIcon($event: UploadFileData): void {
    this.escola.icon_path = $event.thumb;
    this.iconFile = null;
  }

  removeVideo(item: string): void {
    const index = this.escola.videos.indexOf(item);

    if (index >= 0) {
      this.escola.videos.splice(index, 1);
    }
  }

  addVideo(event: MatChipInputEvent): void {
    if (this.videosControl.valid) {
      if (!this.videosControl.value) {
        return;
      }

      const input = event.input;
      const value = event.value;

      //verifica se é um vídeo do youtube normal e converte em url de embed
      if (value.indexOf('youtube') !== -1) {
        const videoId = value.split('v=')[1];
        this.escola.videos.unshift('https://www.youtube.com/embed/' + videoId);
      } else {
        //verifica se é um vídeo do youtube encurtado e converte em url de embed
        if (value.indexOf('youtu.be') !== -1) {
          const videoId = value.split('.be/')[1];
          this.escola.videos.unshift('https://www.youtube.com/embed/' + videoId);
        } else {
          //verifica se é um vídeo do vimeo e converte em url de embed
          if (value.indexOf('vimeo') !== -1) {
            const videoId = value.split('vimeo.com/')[1];
            this.escola.videos.unshift('https://player.vimeo.com/video/' + videoId);
          } else {
            //verifica se é um vídeo do vimeo encurtado e converte em url de embed
            if (value.indexOf('vimeo') !== -1) {
              const videoId = value.split('.com/')[1];
              this.escola.videos.push('https://player.vimeo.com/video/' + videoId);
            } else if ((value || '').trim()) {
              this.escola.videos.unshift(value);
            }
          }
        }
      }

      // Add our tag
      /*if ((value || '').trim()) {
        this.escola.videos.push(value.trim());
      }*/

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.tagControl.setValue(null);
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.escola.videos, event.previousIndex, event.currentIndex);
  }

  vchange(event: google.maps.places.PlaceResult | string): void {
    console.log(event);
  }

  proprietario(): void {
    this.saving = true;
    this.userService.proprietario(this.escola);
    // TODO: return a promisse instead of a timer
    timer(5000).subscribe((value) => {
      this.saving = false;
    });
    this.analytics.clickSolicitarProprietario(this.escola);
  }

  horarioFuncionamento(type: 'min' | 'max', value: string): void {
    switch (type) {
      case 'min':
        this.escola.horario_funcionamento_min = value;
        break;
      case 'max':
        this.escola.horario_funcionamento_max = value;
        break;
    }
  }

  decimalParser(event: any): number {
    if (event !== null) {
      const c = event
        .toString()
        .replace(/[^\d,]/g, '')
        .replace(',', '.');
      if (c.length > 0) {
        return c;
      }
    }
    return null;
  }

  // TODO: add a debounce ou timeout to make one single call
  markForReload(): void {
    // this.escolaGalleryComponent.loadImages();
  }

  buySchool(): void {
    this.saving = true;
    this.pagseguroService.buy(this.pagseguroService.plano.perfil_escola.nome, this.escola.codigo).then(() => (this.saving = false));
  }

  ativaToggle(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.escola.data_desativada = null;
      this.escola.motivo_desativada = null;
    } else {
      const dialogRef = this.matDialog.open(DialogMotivo, {
        data: this.escola,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result != null) {
          // console.log('result:' + JSON.stringify(result));
          this.escola = result;
        }
      });
    }
  }

  requestInfo(fieldName: string, responseDialog: boolean = true): void {
    const user = this.userService.getCurrentUser();
    this.analytics.requestEscolaField(this.escola, fieldName, user);
    // firebase.functions().httpsCallable('event_request_info')({field_name: fieldName, cod_escola: this.escola.codigo});

    if (user == null) {
      this.matDialog.open(LoginDialogComponent);

      // Include this event to run after user login
      const subscription = this.userService.userDataEvent.subscribe((value) => {
        if (value !== null) {
          const loggedUser = this.userService.getCurrentUser();
          if (loggedUser !== null && this.userService.userData?.data_aceite) {
            this.analytics.requestEscolaField(this.escola, fieldName, user);
            // firebase.functions().httpsCallable('event_request_info')({field_name: fieldName, cod_escola: this.escola.codigo});
            this.matDialog.closeAll();
          }
        }

        if (responseDialog) this.matDialog.open(DialogRequestInfoResponse);

        subscription.unsubscribe();
      });
    } else {
      this.analytics.sendEventMail(this.escola, user);

      if (responseDialog) this.matDialog.open(DialogRequestInfoResponse);
    }
  }

  infoBottom(indiceTitle: string): void {
    for (const item of this.indicesInfo) {
      if (item.title === indiceTitle) {
        this._bottomSheet.open(BottomSheetInfo, { data: item });
      }
    }
  }

  raiox(): void {
    // TODO: move this connetion to a service of it's on
    const raioxURL = 'https://us-central1-firesample-7bd56.cloudfunctions.net/raiox';
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        // const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
        // this.httpClient.get(raioxURL, {responseType: 'arraybuffer', headers}).subscribe((data) => {
        //   const blob = new Blob([data], {});
        //   const url = window.URL.createObjectURL(blob);
        //   const pwa = window.open(url);
        //   if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
        //     alert( 'Please disable your Pop-up blocker and try again.');
        //   }
        // });

        // console.log('Sending request to', raioxURL, 'with ID token in Authorization header.');
        // const req = new XMLHttpRequest();
        // req.addEventListener('load', () => {
        //   console.log(req.responseText);
        // });
        // req.addEventListener('error', () => {
        //   console.log('There was an error');
        // });
        // req.open('GET', raioxURL, true);
        // req.setRequestHeader('Authorization', 'Bearer ' + token);
        // req.send();

        const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
        this.httpClient
          .get(raioxURL, {
            headers,
            responseType: 'blob' as 'json',
            observe: 'body' as 'body',
            params: { codigo: this.escola.codigo },
          })
          .subscribe((response: any) => {
            // console.log(JSON.stringify(response));
            // console.log(JSON.stringify(response.body));
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            let fileName = `Raio-X_Escola_${this.escola.codigo}.xlsx`;
            if (response.headers?.get('content-disposition')) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = fileNameRegex.exec(response.headers.get('content-disposition'));
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }
            downloadLink.setAttribute('download', fileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
          });
      });
  }

  newMessage($event: any) {
    if (!this.chatVisible) {
      this.chatBadge = '!';
    }
  }

  toggleChat(): void {
    this.chatVisible = !this.chatVisible;
    if (this.chatVisible) {
      this.chatBadge = null;
    }
  }

  calculeAfinidade() {
    this.router.navigate(['perfil-afinidade']);
  }
}
