import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PageAboutComponent } from '../page/page-about/page-about.component';
import { PageContatoComponent } from '../page/page-contato/page-contato.component';
import { PageEscolaComponent } from '../page/page-escola/page-escola.component';
import { PageDuvidasComponent } from '../page/page-duvidas/page-duvidas.component';
import { PagePoliticaComponent } from '../page/page-politica/page-politica.component';
import { PageRegrasComponent } from '../page/page-regras/page-regras.component';
import { PageTermosComponent } from '../page/page-termos/page-termos.component';
import { MapaComponent } from '../mapa/mapa.component';
import { PageHomeComponent } from '../page/page-home/page-home.component';
import { EscolaDetailComponent } from '../layout/escola-detail/escola-detail.component';
import { PagePerfilComponent } from '../page/page-perfil/page-perfil.component';
import { PageReviewsComponent } from '../page/page-reviews/page-reviews.component';
import { UserEscolasComponent } from '../user/escolas/user-escolas.component';
import { PageFuncionaComponent } from '../page/page-funciona/page-funciona.component';
import { PagePlanosComponent } from '../page/page-planos/page-planos.component';
import { ListaProprietarioComponent } from '../admin/lista-proprietario/lista-proprietario.component';
import { PageCellepComponent } from '../page/page-cellep/page-cellep.component';
import { PageBlogComponent } from '../page/page-blog/page-blog.component';
import { ListaParceiroComponent } from '../admin/lista-parceiro/lista-parceiro.component';
import { PageParceirosComponent } from '../page/page-parceiros/page-parceiros.component';
import { PageListaComponent } from '../page/page-lista/page-lista.component';
import { CalculadoraComponent } from '../calculadora/calculadora.component';
import { PageDiretorioComponent } from '../page/page-diretorio/page-diretorio.component';
import { PageColecaoComponent } from '../page/page-colecao/page-colecao.component';
import { TreeColecaoComponent } from '../admin/tree-colecao/tree-colecao.component';
import { RoomlistComponent } from '../chat/roomlist/roomlist.component';
import { PerfilAfinidadeComponent } from '../layout/perfil-afinidade/perfil-afinidade.component';
import { PageEscolasComponent } from '../page/page-escolas/page-escolas.component';
import { NovaEscolaComponent } from '../admin/nova-escola/nova-escola.component';
import { TemplateOldComponent } from '../templates/template-old/template-old.component';
import { TemplateNewComponent } from '../templates/template-new/template-new.component';
import { EscolaDetailNewComponent } from '../layout/escola-detail-new/escola-detail-new.component';
import { TemplateEditComponent } from '../templates/template-edit/template-edit.component';
import { GeralComponent } from '../layout/edit/geral/geral.component';
import { RedirectGuard } from '../service/redirectGuard';

const isBlog: boolean = window.location.hostname.startsWith('blog');

const routes: Routes = [
  {
    path: '',
    component: TemplateOldComponent,
    children: [{ path: '', component: isBlog ? PageBlogComponent : PageHomeComponent }],
  },
  {
    path: 'blog',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageBlogComponent }],
  },
  { path: 'home', redirectTo: '' },
  {
    path: 'about',
    redirectTo: 'quem-somos',
  },
  {
    path: 'quem-somos',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageAboutComponent }],
  },
  {
    path: 'contato',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageContatoComponent }],
  },
  {
    path: 'contato/:p',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageContatoComponent }],
  },
  /*{
    path: 'escola',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageEscolaComponent }],
  },*/
  {
    path: 'escola',
    redirectTo: '',
  },
  {
    path: 'conhecer',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageDuvidasComponent }],
  },
  {
    path: 'duvidas',
    redirectTo: 'conhecer',
  },
  /*{
    path: 'politica',
    component: TemplateOldComponent,
    children: [{ path: '', component: PagePoliticaComponent }],
  },*/
  {
    path: 'politica',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { 
      externalUrl: 'https://www.blog.schooladvisor.com.br/privacidade'
    },
  },
  /*{
    path: 'funciona',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageFuncionaComponent }],
  },*/
  {
    path: 'funciona',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { 
      externalUrl: 'https://www.blog.schooladvisor.com.br/ferramentas'
    }
  },
  /*{
    path: 'regras',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageRegrasComponent }],
  },*/
  {
    path: 'regras',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { 
      externalUrl: 'https://www.blog.schooladvisor.com.br/conduta'
    }
  },
  /*{
    path: 'termos',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageTermosComponent }],
  },*/
  {
    path: 'termos',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { 
      externalUrl: 'https://www.blog.schooladvisor.com.br/termos-de-uso'
    }
  },
  {
    path: 'planos',
    redirectTo: 'conhecer',
  },
  {
    path: 'mapa',
    component: TemplateOldComponent,
    children: [{ path: '', component: MapaComponent }],
  },
  {
    path: 'busca-escolas',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageEscolasComponent }],
  },
  /*{
    path: 'escolas-old/:codigo/:nome',
    component: TemplateOldComponent,
    children: [{ path: '', component: EscolaDetailComponent }],
  },*/
  {
    path: 'escolas/:codigo/:nome',
    component: TemplateNewComponent,
    children: [{ path: '', component: EscolaDetailNewComponent }],
  },
  {
    path: 'escolas/edit/geral/:codigo',
    component: TemplateEditComponent,
    children: [{ path: '', component: GeralComponent }],
  },
  {
    path: 'lista/:nome',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageDiretorioComponent }],
  },
  {
    path: 'lista/:nome/:nome2',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageDiretorioComponent }],
  },
  {
    path: 'artigos',
    redirectTo: 'blog',
  },
  {
    path: 'minhas_escolas',
    component: TemplateOldComponent,
    children: [{ path: '', component: UserEscolasComponent }],
  },
  {
    path: 'lista_proprietario',
    component: TemplateOldComponent,
    children: [{ path: '', component: ListaProprietarioComponent }],
  },
  /*{
    path: 'cellep',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageCellepComponent }],
  },*/
  {
    path: 'cellep',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { 
      externalUrl: 'https://www.guiaparaescolas.com.br/'
    }
  },
  {
    path: 'lista_parceiro',
    component: TemplateOldComponent,
    children: [{ path: '', component: ListaParceiroComponent }],
  },
  /*{
    path: 'parceiros',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageParceirosComponent }],
  },*/
  {
    path: 'parceiros',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: { 
      externalUrl: 'https://www.guiaparaescolas.com.br/'
    }
  },
  {
    path: 'calculadora-de-gastos-escola',
    component: TemplateOldComponent,
    children: [{ path: '', component: CalculadoraComponent }],
  },
  {
    path: 'calculadora',
    redirectTo: '/calculadora-de-gastos-escola',
    pathMatch: 'full',
  },
  {
    path: 'tree_colecao',
    component: TemplateOldComponent,
    children: [{ path: '', component: TreeColecaoComponent }],
  },
  {
    path: 'colecao/:codigo/:nome',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageColecaoComponent }],
  },
  {
    path: 'diretorio/:codigo/:nome',
    component: TemplateOldComponent,
    children: [{ path: '', component: PageDiretorioComponent }],
  },
  /*{
    path: 'chats/:cod_escola/:nome',
    component: TemplateOldComponent,
    children: [{ path: '', component: RoomlistComponent }],
  },*/
  {
    path: 'perfil-afinidade',
    component: TemplateOldComponent,
    children: [{ path: '', component: PerfilAfinidadeComponent }],
  },
  {
    path: 'admin/nova-escola',
    component: TemplateOldComponent,
    children: [{ path: '', component: NovaEscolaComponent }],
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 100],
      anchorScrolling: 'disabled',
      // useHash: false, relativeLinkResolution: 'legacy'
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
