<div *ngIf="getHasTags() && this.escola.codigo !== 35131891" id="principais-caracteristicas" class="row mb-5">
  <div class="col-12 mb-3">
    <h2 class="text-black font-size-biggest mb-0">Principais características da escola</h2>
  </div>

  <div class="col-12">
    <div class="tags">
      <ul class="d-flex flex-wrap">
        <ng-container>
          <ng-container>
            <li *ngFor="let t of this.getTagsOrdered()" class="tag font-size-regular semibold">
              {{ t.percent}}% {{ t.tag }}
            </li>
          </ng-container>

          <!-- ng-container *ngIf="this.escola.tags.school && this.escola.tags.school.length > 0">
            <li *ngFor="let tag of this.escola.tags.school" class="tag font-size-regular semibold">{{ tag }}</li>
          </ng-container -->
        </ng-container>
      </ul>
    </div>
  </div>
</div>
