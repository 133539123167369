import { Component, Renderer2, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['../../../css/admin-edit/bootstrap-grid.min.css', '../../../css/admin-edit/bootstrap-reboot.min.css', '../../../css/admin-edit/bootstrap.min.css', '../../../css/admin-edit/global.css', '../../../css/admin-edit/admin.css', '../../../css/admin-edit/header.css', '../../../css/admin-edit/aside-menu.css', '../../../css/admin-edit/form.css', '../../../css/admin-edit/gallery.css', './template-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateEditComponent implements OnInit, OnDestroy {
  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'admin');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'admin');
  }
}
