<main role="main">
  <div class="main-content">
    <!-- HEADER COMPONENT -->
    <header class="header mb-4">
      <!-- USER NAME -->
      <span class="font-size-small text-lightgray welcome">Olá, School Advisor</span>
      <!-- END USER NAME -->

      <!-- MENU -->
      <nav>
        <ul class="d-flex flex-row align-items-center justify-content-end mb-0 ps-4">
          <li class="featured">
            <a href="#" title="Minha Conta" class="d-flex flex-row align-items-center font-size-small text-green border-green semibold"><span>Minha Conta</span></a>
          </li>
        </ul>
      </nav>
      <!-- END MENU -->
    </header>
    <!-- END HEADER COMPONENT -->

    <!-- CONTENT -->
    <section class="content">
      <div class="container-fluid">
        <!-- TITLE -->
        <div class="row mb-4">
          <!-- COL -->
          <div class="col-12 mb-3">
            <h2 class="text-black font-size-biggest mb-0">Geral</h2>
          </div>
          <!-- COL -->

          <!-- DIVISOR -->
          <div class="divisor"></div>
          <!-- END DIVISOR -->
        </div>
        <!-- END TITLE -->

        <!-- FORM -->
        <form class="form needs-validation" novalidate>
          <!-- ROW -->
          <div class="row mb-4">
            <!-- COL -->
            <div class="col-12 mb-4">
              <!-- CHECKBOX COMPONENT -->
              <div class="custom-check form-group">
                <div class="col-12 mb-1">
                  <label for="" class="form-label font-size-regular text-gray bold mb-0">Escola Parceira</label>
                </div>
                <div class="form-check col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                  <input class="form-check-input" type="checkbox" name="inlineCheckOptions" id="validationFormCheck1" value="option1" required />
                  <label class="form-check-label font-size-small text-lightgray" for="validationFormCheck1">Parceira</label>
                </div>
                <div class="form-check col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                  <input class="form-check-input" type="checkbox" name="inlineCheckOptions2" id="validationFormCheck2" value="option2" required />
                  <label class="form-check-label font-size-small text-lightgray" for="validationFormCheck2">Verificada</label>
                </div>
                <div class="form-check col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12">
                  <input class="form-check-input" type="checkbox" name="inlineCheckOptions3" id="validationFormCheck3" value="option3" required />
                  <label class="form-check-label font-size-small text-lightgray" for="validationFormCheck3">Comum</label>
                </div>
              </div>
              <!-- END CHECKBOX COMPONENT -->
            </div>
            <!-- END COL -->

            <!-- COL -->
            <div class="col-12 mb-4">
              <!-- TEXT COMPONENT -->
              <div class="custom-input text form-group">
                <div class="col-12 mb-1">
                  <label for="" class="form-label font-size-regular text-gray bold mb-0">Nome da escola</label>
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" id="" name="nome-escola" placeholder="" required />
                </div>
              </div>
              <!-- END TEXT COMPONENT -->
            </div>
            <!-- END COL -->

            <!-- COL -->
            <div class="col-12 mb-4">
              <!-- UPLOAD WITH PREVIEW COMPONENT -->
              <div class="custom-upload form-group">
                <div class="col-12 mb-1">
                  <label for="" class="form-label font-size-regular text-gray bold mb-0">Logo</label>
                </div>
                <div class="col-12 mb-3">
                  <small class="d-block font-size-smallest text-lightgray mb-1">Formatos: JPG, JPEG, PNG, SVG.</small>
                  <small class="d-block font-size-smallest text-lightgray">Tamanho máximo: 2mb.</small>
                </div>
                <div class="col-12 d-flex flex-wrap align-items-center">
                  <div class="preview">
                    <img id="imgPreview" class="img-preview" alt="Preview da imagem" />
                  </div>
                  <div class="select">
                    <label class="custom-file-label" for="formFile">Escolher arquivo</label>
                    <input type="file" class="custom-file-input" id="formFile" accept=".jpg,.jpeg,.png,.svg" />
                  </div>

                  <div id="errorMessage" style="display: none"></div>
                </div>
              </div>
              <!-- END UPLOAD WITH PREVIEW COMPONENT -->
            </div>
            <!-- END COL -->

            <!-- COL -->
            <div class="col-12 mb-4">
              <!-- GALLERY UPLOAD WITH PREVIEW COMPONENT -->
              <div class="custom-gallery-upload form-group">
                <div class="col-12 mb-1">
                  <label for="" class="form-label font-size-regular text-gray bold mb-0">Fotos</label>
                </div>
                <div class="col-12 mb-3">
                  <small class="d-block font-size-smallest text-lightgray mb-1">Formatos: JPG, JPEG, PNG, SVG.</small>
                  <small class="d-block font-size-smallest text-lightgray">Tamanho máximo por imagem: 2mb.</small>
                </div>
                <div class="col-12 d-flex flex-column">
                  <div id="gallery" class="gallery"></div>

                  <div class="select">
                    <label class="custom-file-label" for="formFiles">Escolher imagens</label>
                    <input type="file" class="custom-file-input" id="formFiles" accept=".jpg,.jpeg,.png,.svg" multiple />
                  </div>

                  <div id="errorMessageGallery" style="display: none"></div>
                </div>
              </div>
              <!-- END GALLERY UPLOAD WITH PREVIEW COMPONENT -->
            </div>
            <!-- END COL -->

            <!-- COL -->
            <div class="col-12 mb-4">
              <!-- TEXT COMPONENT -->
              <div class="custom-input text form-group">
                <div class="col-12 mb-1">
                  <label for="" class="form-label font-size-regular text-gray bold mb-0">Endereço</label>
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" id="" name="endereco" placeholder="" />
                </div>
              </div>
              <!-- END TEXT COMPONENT -->
            </div>
            <!-- END COL -->

            <!-- COL -->
            <div class="col-12 mb-4">
              <!-- TEXT COMPONENT -->
              <div class="custom-input text form-group">
                <div class="col-12 mb-1">
                  <label for="" class="form-label font-size-regular text-gray bold mb-0">Quantidade de alunos</label>
                </div>
                <div class="col-12">
                  <input type="number" class="form-control" id="" name="qtd-alunos" placeholder="" />
                </div>
              </div>
              <!-- END TEXT COMPONENT -->
            </div>
            <!-- END COL -->

            <!-- COL -->
            <div class="col-12 mb-4">
              <!-- RADIO COMPONENT -->
              <div class="custom-radio form-group">
                <div class="col-12 mb-1">
                  <label for="" class="form-label font-size-regular text-gray bold mb-0">Metodologia</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck1" value="Antropológica" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck1">Antropológica</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck2" value="Construtivista" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck2">Construtivista</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck3" value="Humanista" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck3">Humanista</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck4" value="Metodologia própria" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck4">Metodologia própria</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck5" value="Montessoriana" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck5">Montessoriana</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck6" value="Piaget" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck6">Piaget</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck7" value="Progressista Libertadora/Freiriana" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck7">Progressista Libertadora/Freiriana</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck8" value="Progressista Libertária/Democrática" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck8">Progressista Libertária/Democrática</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck9" value="Reggio Emília" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck9">Reggio Emília</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck10" value="Sóciointeracionista" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck10">Sóciointeracionista</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck11" value="Sócioconstrutivista" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck11">Sócioconstrutivista</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck12" value="Tradicional" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck12">Tradicional</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="metodologia" id="metodologiaCheck13" value="Waldorf" required />
                  <label class="form-check-label font-size-small text-lightgray" for="metodologiaCheck13">Waldorf</label>
                </div>
              </div>
              <!-- END RADIO COMPONENT -->
            </div>
            <!-- END COL -->

            <!-- COL -->
            <div class="col-12 mb-4">
              <!-- RADIO COMPONENT -->
              <div class="custom-radio form-group">
                <div class="col-12 mb-1">
                  <label for="" class="form-label font-size-regular text-gray bold mb-0">Bilinguismo</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="bilinguismo" id="bilinguismoCheck1" value="Programa Opcional" required />
                  <label class="form-check-label font-size-small text-lightgray" for="bilinguismoCheck1">Programa Opcional</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="bilinguismo" id="bilinguismoCheck2" value="Sim" required />
                  <label class="form-check-label font-size-small text-lightgray" for="bilinguismoCheck2">Sim</label>
                </div>
                <div class="form-check col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                  <input class="form-check-input" type="radio" name="bilinguismo" id="bilinguismoCheck3" value="Não" required />
                  <label class="form-check-label font-size-small text-lightgray" for="bilinguismoCheck3">Não</label>
                </div>
              </div>
              <!-- END RADIO COMPONENT -->
            </div>
            <!-- END COL -->

            <!-- DIVISOR -->
            <div class="divisor mb-4"></div>
            <!-- END DIVISOR -->

            <!-- COL -->
            <div class="col-12 mt-2">
              <!-- ACTIONS -->
              <div class="actions-form">
                <a href="#" class="btn btn-secondary">Cancelar</a>
                <button class="btn btn-primary" type="submit">Salvar</button>
              </div>
              <!-- END ACTIONS -->
            </div>
            <!-- END COL -->
          </div>
          <!-- END ROW -->
        </form>
        <!-- END FORM -->
      </div>
    </section>
    <!-- END CONTENT -->
  </div>
</main>
