export const environment = {
  production: true,
  useEmulators: false,
  // isomorphicDir: 'http://127.0.0.1:8080/school/isomorphic/',
  // isomorphicDir: 'https://mcodigo.schooladvisor.com.br/school/isomorphic/',
  // isomorphicDir: 'http://localhost:5001/firesample-7bd56/us-central1/',
  GOOGLE_CLIENT_AUTH_ID: '977887305230-6ntlokf1nk33ipereu3ae1ldemienog9.apps.googleusercontent.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyB6kjeYirXkFxYxytmIbKIKtn04xCaC8LQ',
  BUILDERALL_TOKEN: '643817:16f1329a03d34c395face1ebc22d01d0',
  google_onetap_client_id: '680287849093-p2qq9tk23aluf9d6r1e86sno9rfafc96.apps.googleusercontent.com',
  baseDomain: 'https://schooladvisor.com.br',
  // // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  //DEV
  /*firebaseConfig: {
    apiKey: "AIzaSyDEO5zfCMqIooSmlNOO4mrgodv0O3Wfw6I",
    authDomain: "schooladvisordev.firebaseapp.com",
    databaseURL: "https://schooladvisordev-default-rtdb.firebaseio.com",
    projectId: "schooladvisordev",
    storageBucket: "schooladvisordev.appspot.com",
    messagingSenderId: "292749996378",
    appId: "1:292749996378:web:87e0b8a1b61f3460ef0363",
    measurementId: "G-ZCPVDN8XW4"
  },*/

  //PROD
  firebaseConfig: {
    apiKey: 'AIzaSyAyKXaxJkvhMlPrZsR3t85-5tfvO0o2ckU',
    // authDomain: 'firesample-7bd56.firebaseapp.com',
    authDomain: 'schooladvisor.com.br',
    databaseURL: 'https://firesample-7bd56-default-rtdb.firebaseio.com',
    projectId: 'firesample-7bd56',
    storageBucket: 'firesample-7bd56.appspot.com',
    messagingSenderId: '680287849093',
    appId: '1:680287849093:web:b9013d6e785dd54e1177ec',
    measurementId: 'G-RED9RYHSVQ'
  },

  pagseguro: {
    email: 'schooladvisor.financeiro@gmail.com',
    // token_sandbox: '59765CC154744A9EA66B49B4BBC5E905',
    token: 'A344C2A5977E47018312BCE5FAFF785E'
  }
};
