<div
  id="dados-principais"
  [ngClass]="{
    'row school-info': true,
    mobile: isMobile,
    m5: !isMobile
  }"
>
  <div class="d-flex flex-wrap align-items-center mb-3">
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12" style="padding-right: 20px">
      <img [src]="escola.icon_path ? escola.icon_path : 'images/logo.png'" [alt]="this.escola.nome" />
    </div>
    <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
      <!-- NAME -->
      <div class="school-name">
        <h1 class="font-size-title text-black" [ngStyle]="{ 'text-decoration': escola.data_desativada ? 'line-through' : '' }">
          {{ this.escola.nome }}
        </h1>
      </div>
      <!-- END NAME -->

      <!-- RATING -->
      <div class="rating d-flex flex-row align-items-center">
        <div class="item">
          <a href="#avaliacoes" (click)="scrollTo($event, '#avaliacoes')" title="Nota" class="text-black">
            <img src="images/new/school/icon-share-star.svg" alt="Nota" />
            <span *ngIf="this.escola.rate_geral == null || this.escola.rate_geral <= 0" class="text-black font-size-small">-</span>
            <span *ngIf="this.escola.rate_geral && this.escola.rate_geral > 0" class="text-black font-size-small">{{ this.escola.rate_geral | number : "1.1-1" }}</span>
          </a>
        </div>
        <div class="item">
          <a href="#depoimentos" (click)="scrollTo($event, '#depoimentos')" title="depoimentos" class="text-black">
            <img src="images/new/school/icon-share-coments.svg" alt="Comentários" />
            <span *ngIf="reviewCount === 0" class="text-black font-size-small">- depoimentos</span>
            <span *ngIf="reviewCount !== 0" class="text-black font-size-small">{{ this.reviewCount }} depoimentos</span>
          </a>
        </div>
        <div class="item" *ngIf="this.escola.premium == true">
          <a title="Escola Parceira" class="text-black">
            <img src="images/new/icon-share-award.svg" alt="Escola Parceira" />
            <span class="text-black font-size-small">Escola Parceira</span>
          </a>
        </div>
        <div class="item" *ngIf="this.escola.verificado == true">
          <a title="Escola Verificada" class="text-black">
            <img src="images/new/icon-share-verified.svg" alt="Escola Verificada" />
            <span class="text-black font-size-small">Escola Verificada</span>
          </a>
        </div>
        <div class="item" *ngIf="!this.escola.verificado && !this.escola.premium">
          <a title="Escola não Verificada" class="text-black">
            <img src="images/new/icon-share-unverified.svg" alt="Escola não Verificada" />
            <span class="text-black font-size-small">Escola não Verificada</span>
          </a>
        </div>
      </div>
      <!-- END RATING -->
    </div>
  </div>
  <div class="col-12 mb-3">
    <div class="tags">
      <ul class="d-flex flex-wrap">
        <li class="tag font-size-regular semibold" *ngIf="this.escola.nivel_ensino.indexOf('Berçário') >= 0">Berçário</li>
        <li class="tag font-size-regular semibold" *ngIf="this.escola.nivel_ensino.indexOf('Infantil') >= 0">Infantil</li>
        <li class="tag font-size-regular semibold" *ngIf="this.escola.nivel_ensino.indexOf('Fundamental I') >= 0">Fundamental Anos Iniciais</li>
        <li class="tag font-size-regular semibold" *ngIf="this.escola.nivel_ensino.indexOf('Fundamental II') >= 0">Fundamental Anos Finais</li>
        <li class="tag font-size-regular semibold" *ngIf="this.escola.nivel_ensino.indexOf('Ensino Médio') >= 0">Ensino Médio</li>
      </ul>
    </div>
  </div>

  <!-- Exibir apenas no desktop -->
  <div class="col-12" *ngIf="!isMobile">
    <div *ngIf="this.destaquePorcentagem() >= 60" class="item-info mb-3">
      <img src="images/new/school/icon-care.svg" alt="{{ this.destaque }}" />
      <h3 class="font-size-big bold text-black pt-2">{{ this.obterDestaqueTitulo() }}</h3>
      <p class="font-size-regular text-lightgray mb-0 pb-0">{{ this.destaquePorcentagem() }}% {{ this.obterDestaqueDescricao() }}.</p>
    </div>
    <div *ngIf="this.obterMelhorNota() >= 4" class="item-info mb-3">
      <img src="images/new/school/icon-building-estate.svg" alt="{{ this.obterTituloMelhorNota() }}" />
      <h3 class="font-size-big bold text-black pt-2">{{ this.obterTituloMelhorNota() }}</h3>
      <p class="font-size-regular text-lightgray mb-0 pb-0">{{ this.obterDescricaoMelhorNota() }} {{ this.obterMelhorNota() }}.</p>
    </div>
    <div *ngIf="this.familyChoice" class="item-info mb-0">
      <img src="images/new/school/icon-user-heart.svg" alt="Escolha das famílias" />
      <h3 class="font-size-big bold text-black pt-2">Escolha das famílias</h3>
      <p class="font-size-regular text-lightgray mb-0 pb-0">Esta escola recebeu o selo Family's Choice 2024 da SchoolAdvisor.</p>
    </div>
    <div *ngIf="!this.familyChoice && this.showIIESA()" class="item-info mb-0">
      <img src="images/new/school/icon-user-heart.svg" alt="Ótima Infraestrutura" />
      <h3 class="font-size-big bold text-black pt-2">Ótima Infraestrutura</h3>
      <p class="font-size-regular text-lightgray mb-0 pb-0">Esta escola recebeu o selo Destaque Infraestrutura 2024 da SchoolAdvisor.</p>
    </div>
  </div>
  <!-- Fim Exibir apenas no desktop -->
</div>
