import {Injectable } from '@angular/core';
import {Title, Meta} from "@angular/platform-browser";
import {environment} from "../../environments/environment";
import { UrlNormalizerPipe } from "../pipe/url-normalizer.pipe";


@Injectable({
  providedIn: 'root'
})
export class PageService {
    constructor(private titleService: Title, private metaService: Meta) {
    }

    setPageData(title: string = null, 
                description: string = null, 
                url: string = null, 
                keywords: string = null) {
        this.setTitle(title);
        this.setDescription(description);
        this.setCanonical(url);
        this.setKeywords(keywords);
    }

    setTitle(title: string) {
        if (title) {
            title = title + ' | SchoolAdvisor';
        }
        else {
            title = 'SchoolAdvisor | Encontre uma Escola';
        }

        this.titleService.setTitle(title);
    }

    setCanonical(url: string) {
        if (url) {
            url = `${environment.baseDomain}/${url}`;
        }
        else {
            url = environment.baseDomain;
        }

        url = new UrlNormalizerPipe().transform(url);

        const canonicalTag = this.metaService.getTag('rel="canonical"');

        if (canonicalTag) {
            canonicalTag.setAttribute('href', url);
        } else {
            this.metaService.addTag({
                rel: 'canonical',
                href: url
            });
        }
    }

    setDescription(description: string) {
        if (!description) {
            description = "Informações completas, organizadas e confiáveis sobre as escolas particulares brasileiras.";
        }

        const descriptionTag = this.metaService.getTag('name="description"');

        if (descriptionTag) {
            descriptionTag.setAttribute('content', description);
        } else {
            this.metaService.addTag({
                name: 'description',
                content: description
            });
        }
    }

    setKeywords(keywords: string) {
        if (!keywords) {
            keywords = "escola, escolas, escola particular, escola privada, escola infantil, escola fundamental, escola médio, escola ensino médio, escola ensino fundamental, escola ensino infantil, escola ensino fundamental, escola ensino médio, escola ensino infantil, escola particular";
        }

        const keywordsTag = this.metaService.getTag('name="keywords"');

        if (keywordsTag) {
            keywordsTag.setAttribute('content', keywords);
        } else {
            this.metaService.addTag({
                name: 'keywords',
                content: keywords
            });
        }
    }

}